import React, {useContext} from 'react';
import './style.scss';
import {Table, Tag, Popover, Button} from 'antd';
import {epochToDate, getUSDollar} from '../../libs/utility';
import {Context} from '../../store';
import { UpCircleOutlined, DownCircleOutlined, CloseCircleOutlined, StarFilled, PercentageOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {ReactComponent as TwLogo} from '../../assets/icons/tw-logo2.svg';

const Last24HoursDealsTable = ({data}) => {
    const {state} = useContext(Context);

    const linkPrefix = state.marketType === "FUTURES" ? "futures" : "spot";

    const columns = [
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: text => tagBuilder(text),
          filters: [
              {
                  text: 'LONG',
                  value: 'OPEN LONG'
              },
              {
                  text: 'SHORT',
                  value: 'OPEN SHORT'
              }
          ],
          onFilter: (value, record) => record.status.indexOf(value) === 0
        },
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            key: 'symbol',
            render: (symbol, record) => symbolBuilder(symbol, record),
            sorter: (a, b) => ('' + a.symbol).localeCompare(b.symbol),
        },
        /*
        {
            title: 'Pos Model',
            dataIndex: 'positionModel',
            key: 'positionModel',
            render: text => text
        },
        {
            title: 'Maturity',
            dataIndex: 'maturity',
            key: 'maturity',
            render: text => text
        },
        */
        {
            title: 'Pos Type',
            dataIndex: 'positionType',
            key: 'positionType',
            render: (text, record) => posBuilder(record),
        },
        {
            title: 'Open Price',
            dataIndex: 'openPrice',
            key: 'openPrice',
            render: price => priceBuilder(price)
        },
        {
            title: 'Current Price',
            dataIndex: 'closePrice',
            key: 'closePrice',
            render: price => `$ ${price}`
          },
        {
          title: 'Start Date',
          key: 'openDate',
          dataIndex: 'openDate',
          render: date => epochToDate(date).toLocaleString()
        },
        {
            title: 'Pass Time',
            key: 'time',
            dataIndex: 'time',
            render: (time, record) => `${time}`,
            sorter: (a, b) => a.time - b.time,
        },
        {
            title: 'Reverse (C)',
            key: 'closeReverse',
            dataIndex: 'closeReverse',
            render: (closeReverse, record) => reverseBuilder(closeReverse, record.closeReverseIndex),
            sorter: (a, b) => a.closeReverse - b.closeReverse,
        },
        {
              title: 'Reverse (M)',
              key: 'maxReverse',
              dataIndex: 'maxReverse',
              render: (maxReverse, record) => reverseBuilder(maxReverse, record.maxReverseIndex),
              sorter: (a, b) => a.maxReverse - b.maxReverse,
        },
        {
            title: 'First Reverse',
            key: 'firstReverse',
            dataIndex: 'firstReverse',
            render: (firstReverse, record) => reverseBuilder(firstReverse, 1),
            sorter: (a, b) => a.firstReverse - b.firstReverse,
        },
        {
            title: 'Max Profit',
            key: 'highProfit',
            dataIndex: 'highProfit',
            render: (highProfit, record) => reverseBuilder(highProfit, record.highPriceIndex),
            sorter: (a, b) => a.highProfit - b.highProfit,
        },
        {
            title: 'High Profit',
            key: 'mostProfit',
            dataIndex: 'mostProfit',
            render: (mostProfit, record) => reverseBuilder(mostProfit, record.topNeedleIndex),
            sorter: (a, b) => a.mostProfit - b.mostProfit,
        },
        {
            title: 'Profit/Loss',
            key: 'pnl',
            dataIndex: 'pnl',
            render: (pnl, record) => `${pnl} %`,
            sorter: (a, b) => a.pnl - b.pnl,
        }
      ];

    const tagBuilder = (text) => {
        let color = null;
        let icon = null;
        switch (text) {
            case 'LONG':
                color = "#87d068";
                icon = <UpCircleOutlined />;
                break;
            case 'SHORT':
                color = "#f50";
                icon = <DownCircleOutlined />;
                break;
            case 'CLOSE POSITION':
                color = "#3b5081";
                icon = <CloseCircleOutlined />;
                break;
            default:
                color = "black";
                break;
        }
        return <div className='deals-tag'>
                    <Tag icon={icon} color={color}>{text.toUpperCase()}</Tag>
               </div>
    }

    const openUrl = (symbol) => {
        window.open(`https://www.tradingview.com/chart/fu2csY4t/?symbol=${symbol.market.toUpperCase()}%3A${symbol.symbol}`, '_blank');
    }
    const symbolBuilder = (symbol, record) => {
        const sym = state.symbols.find(x => x.symbol === symbol);
        const imageName = sym.iconUrl; // state.marketType === "FUTURES" ? sym?.baseAsset?.toLowerCase() + '.svg' : sym.icon;
        return <div className="symbol">
            <Link to={`/currencies/details/${symbol.replace('USDT', '').toLowerCase()}`} state={{uniqId: sym.id}}>
                <img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${imageName}`} alt="" />
                <span><strong>{sym.baseAsset}</strong></span>
                {/*
                <span className={`risk ${record.riskLevel}`}>
                    {record.riskLevel === "star" && <StarFilled style={{color: '#17a5f9', fontSize: 16}} />}
                </span>                
                */}
            </Link>
            <Button type="primary" shape="circle" className='btn-black' style={{ marginLeft: 10}} icon={<span role="img" aria-label="tw" className="anticon anticon-history"><TwLogo /></span>} size={"small"} onClick={() => openUrl(sym)} />
        </div>
    }

    const posBuilder = (record) => {
        return <div className="pos-type">
            <Tag color={"#87d068"}>{record.posType}</Tag>
        </div>
    }

    const reverseBuilder = (reverse, index) => {
        return <div className="reverse">
            <span className="reverse-value">{reverse} %</span>
            {index > 0 && <span className="reverse-index">{index}</span>}
        </div>
    }

    const percentageColumns = [
        {
            title: 'Profit',
            dataIndex: 'profitPrice',
            key: 'profitPrice'
        },
        {
            title: '%',
            dataIndex: 'percent',
            key: 'percent'
        },
        {
            title: 'Loss',
            dataIndex: 'lossPrice',
            key: 'lossPrice'
        }
    ];

    const percentTable = (price) => {
        let fixed = price.toString().split('.')[1] || 0;
        const percentData = [];
        const numPrice = Number(price);
        for (let i = 1; i < 6; i++) {
            const obj = {
                percent: i,
                profitPrice: ((numPrice / 100) * i + numPrice).toFixed(fixed.length),
                lossPrice: ((numPrice / 100) * -i + numPrice).toFixed(fixed.length),
            }
            percentData.push(obj);          
        }
        return <Table columns={percentageColumns} dataSource={percentData} bordered pagination={false} />
    }

    const priceBuilder = (price) => {
        return <div className="price">
            <span className='price-text'>{getUSDollar(price, 8)}</span>
            <Popover placement="rightTop" title={"Percentages"} content={percentTable(price)} trigger="click">
                <Button type="primary" size="small" shape="circle" icon={<PercentageOutlined />}></Button>
            </Popover>
        </div>
    }

    const rowClassNameBuilder = (record, index) => {
        if(state.analysisModel === "rsiDivergence" || state.analysisModel === "AI") {
            if(record.pnl > 0) {
                return "soft-green";
            }
            else if (record.pnl === 0) {
                return "soft-gray";
            }
            else {
                return "soft-red";
            }
        }
        else if(!state.enabledSimulator && (state.analysisModel !== "depthHill" && state.analysisModel !== "explosionLoose")) {
            return record.mostProfit > 1 ? "soft-green" : "soft-red";
        }
        else {
            if(record.pnl > 0.1) {
                return "soft-green";
            }
            else if (record.pnl >= 0 && record.pnl <= 0.1) {
                return "soft-gray";
            }
            else {
                return "soft-red";
            }
        }
    }

    return (
        <div className='last-24-hours-deals-table'>
            <Table columns={columns} dataSource={data} bordered pagination={false} rowClassName={rowClassNameBuilder}/>
        </div>
    );
};


export default Last24HoursDealsTable;